import { request } from "./request.js";
// 商品信息
export function GoodsLists(data = {}) {
  return request({
    url: "Shop/goodslists",
    method: "post",
    data,
  });
}
export function GoodsSave(data = {}) {
  return request({
    url: "Shop/goodssave",
    method: "post",
    data,
  });
}
export function GoodsDel(data = {}) {
  return request({
    url: "Shop/goodsdel",
    method: "post",
    data,
  });
}
// 删除图片上传时的临时文件
export function DelUpload(data = {}) {
  return request({
    url: "index/del_upload_img",
    method: "post",
    data,
  });
}

// 商品分类
export function TypeLists(data = {}) {
  return request({
    url: "Shop/typelists",
    method: "post",
    data,
  });
}
export function TypeSave(data = {}) {
  return request({
    url: "Shop/typesave",
    method: "post",
    data,
  });
}
export function TypeDel(data = {}) {
  return request({
    url: "Shop/typedel",
    method: "post",
    data,
  });
}

// 订单管理
export function OrderLists(data = {}) {
  return request({
    url: "Shop/orderLists",
    method: "post",
    data,
  });
}
export function OrderSave(data = {}) {
  return request({
    url: "Shop/orderSave",
    method: "post",
    data,
  });
}
export function OrderCancel(data = {}) {
  return request({
    url: "Shop/orderCancel",
    method: "post",
    data,
  });
}

