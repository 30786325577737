import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
import Index from "../views/Index/Index.vue";
import Home from "../views/Index/Home.vue";
import Login from "../views/Login.vue";
import Userinfo from "../views/Index/Userinfo.vue";
import MenuList from "../views/Bew/MenuList.vue";
import RoleList from "../views/Bewadmin/RoleList.vue";
import DepList from "../views/Bewadmin/DepList.vue";
import UserList from "../views/Bewadmin/UserList.vue";
import TestList from "../views/BewTest/TestList.vue";

// 商城管理
import GoodsList from "../views/Shop/GoodsList.vue";
import GoodsType from "../views/Shop/GoodsType.vue";

// 订单管理
import OrderList from "../views/Shop/OrderList.vue";

// 小程序管理
import AppletUser from "../views/applet/user.vue";

const routes = [
  {
    path: "/",
    alias: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "登录",
    },
  },
  {
    path: "/index",
    name: "Index",
    component: Index,
    meta: {
      title: "首页",
    },
    children: [
      {
        path: "home",
        name: "Home",
        component: Home,
        meta: {
          title: "首页",
        },
      },
      {
        path: "userinfo",
        name: "Userinfo",
        component: Userinfo,
        meta: {
          title: "个人中心",
        },
      },
      {
        path: "menulist",
        name: "MenuList",
        component: MenuList,
        meta: {
          title: "导航管理",
        },
      },
      {
        path: "rolelist",
        name: "RoleList",
        component: RoleList,
        meta: {
          title: "角色管理",
        },
      },
      {
        path: "deplist",
        name: "DepList",
        component: DepList,
        meta: {
          title: "部门管理",
        },
      },
      {
        path: "userlist",
        name: "UserList",
        component: UserList,
        meta: {
          title: "管理员管理",
        },
      },
      {
        path: "testlist",
        name: "TestList",
        component: TestList,
        meta: {
          title: "演示",
        },
      },
      {
        path: "GoodsList",
        name: "GoodsList",
        component: GoodsList,
        meta: {
          title: "商品列表",
        },
      },
      {
        path: "goodsType",
        name: "GoodsType",
        component: GoodsType,
        meta: {
          title: "商品分类",
        },
      },
      {
        path: "orderList",
        name: "OrderList",
        component: OrderList,
        meta: {
          title: "订单查询",
        },
      },
      {
        path: "appletUser",
        name: "appletUser",
        component: AppletUser,
        meta: {
          title: "社区管理",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  document.title = to.meta.title;
});

export default router;
