/*
 * @Descripttion: 
 * @Author: 银河以北
 * @Date: 2024-12-07 17:02:42
 * @LastEditors: 银河以北
 * @LastEditTime: 2024-12-08 10:49:47
 */
import { request } from "./request.js";
export function UserLists(data = {}) {
    return request({
        url: "Applet/getUserList",
        method: "post",
        data
    });
}

export function getByRecord(data = {}) {
    return request({
        url: "Applet/getOrderAll",
        method: "post",
        data
    });
}


export function UserDel(data = {}) {
    return request({
        url: "Applet/UserDel",
        method: "post",
        data,
    });
}