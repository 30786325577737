import { request } from "./request.js";
export function RoleLists(data = {}) {
    return request({
        url: "Bewadmin/rolelists",
        method: "post",
        data
    });
}
export function RoleSave(data = {}) {
    return request({
        url: "Bewadmin/rolesave",
        method: "post",
        data,
    });
}
export function RoleDel(data = {}) {
    return request({
        url: "Bewadmin/roledel",
        method: "post",
        data,
    });
}


export function DepLists(data = {}) {
    return request({
        url: "Bewadmin/deplists",
        method: "post",
        data
    });
}
export function DepSave(data = {}) {
    return request({
        url: "Bewadmin/depsave",
        method: "post",
        data,
    });
}
export function DepDel(data = {}) {
    return request({
        url: "Bewadmin/depdel",
        method: "post",
        data,
    });
}
export function UserLists(data = {}) {
    return request({
        url: "Bewadmin/userlists",
        method: "post",
        data
    });
}
export function UserSave(data) {
    return request({
        url: "Bewadmin/usersave",
        method: "post",
        data,
    });
}
export function UserDel(data) {
    return request({
        url: "Bewadmin/userdel",
        method: "post",
        data,
    });
}