<template>
    <!-- <el-button type="primary" size="default" icon="Plus" style="margin-bottom:20px" @click="showDialog(1)"> 添加
    </el-button> -->
    <el-table :data="tableData" size="small" border stripe>
        <el-table-column prop="uid" label="UID" min-width="60" />
        <el-table-column prop="uaccount" label="昵称" min-width="120" />
        <!-- <el-table-column prop="uname" label="姓名" min-width="80" /> -->
        <el-table-column prop="phone" label="手机号" min-width="100" />
        <!-- <el-table-column prop="balance" label="余额" min-width="100" /> -->
        <el-table-column prop="uemail" label="邮箱" min-width="60" />
        <!-- <el-table-column prop="status" label="状态" min-width="60" /> -->
        <el-table-column prop="status" label="状态" min-width="60">
            <template #default="scope">
                <el-tag effect="dark" :type="scope.row.status==1?'success':'danger'">{{scope.row.status==1?'开启':'关闭'}}
                </el-tag>
                <!-- {{  scope.row.status}} -->
            </template>
        </el-table-column>
        <!-- <el-table-column prop="times_login" label="登录次数" min-width="80" /> -->
        <el-table-column prop="addresses" label="地址" />
        <!-- <el-table-column prop="time_last" label="最后登录时间" min-width="100" /> -->
        <el-table-column prop="cz" label="操作" min-width="160" align="center">
            <template #default="scope">
                <el-button type="primary" size="default" icon="Histogram" @click="showDialog(2, scope.row)">购买记录
                </el-button>
                <el-button type="danger" size="default" icon="Delete" @click="delData(scope.row)">注销社区</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog" :width="'1000px'" title="够买记录">
        <div class="buy-records">
            <div class="buy-info">
                <div>下单次数：<span>{{ buyInfo.buyNum }}</span></div>
                <div>商品件数：<span>{{ buyInfo.itemNum }}</span></div>
                <div>累计消费：<span>{{ buyInfo.money }}</span></div>
            </div>
            <div class="records-list">
                <div class="records-item" v-for="(item, index) in buyRecord" :key="index">
                    <div class="record-num">
                        <div>订单号：{{ item.ooid }}</div>
                        <div>
                            <el-tag effect="dark">完成</el-tag>
                        </div>
                    </div>
                    <div class="goods-info">
                        <div class="goods-img">
                            <img :src="'https://test-server.yinheyibei.cn/'+item.goods.img_s[0].data"
                                style="width: 100%;height: 100%;" alt="">
                        </div>
                        <div class="info">
                            <div style="font-size: 16px;font-weight: 600;">{{ item.goods.title }}</div>
                            <div style="font-size: 12px;">单价：<span style="color:red">{{ item.goods.nprice }}</span>
                            </div>
                            <div style="font-size: 12px;">够买数量：x{{ item.num}}</div>
                            <div style="font-size: 12px;">合计：<span
                                    style="color:red;font-weight: 600;">{{ item.goods.nprice*item.num }}</span>
                            </div>
                            <div style="font-size: 12px;">{{ item.time}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </el-dialog>
</template>
<script>
    import {
        toRefs,
        reactive
    } from "vue";
    import {
        UserLists,
        getByRecord,
        // UserSave,
        UserDel
    } from "@/network/applet";
    import {
        ElMessage,
        ElMessageBox
    } from "element-plus";
    export default {
        name: "appletUser",
        setup() {
            const state = reactive({
                tableData: [],
                role: [],
                dep: [],
                dialog: false,
                title: "添加",
                password_placeholder: "请输入密码",
                form: {
                    uid: 0,
                    r_id: 0,
                    dep_id: 0,
                    account: "",
                    password: "",
                    name: "",
                    phone: "",
                    qq: "",
                    sex: 1,
                    status: 1,
                },
                buyRecord: [],
                buyInfo: {
                    buyNum: 0,
                    itemNum: 0,
                    money: 0
                }
            });
            UserLists().then((e) => {
                state.tableData = e
                // state.tableData = e.lists;
                // state.role = e.role;
                // state.dep = e.dep;
            })
            const showDialog = (e, row) => {
                getByRecord({
                    uid: row.uid
                }).then(res => {
                    state.dialog = true;
                    state.buyRecord = res
                    state.buyInfo.buyNum = 0
                    state.buyInfo.itemNum = 0
                    state.buyInfo.money = 0
                    state.buyRecord.forEach(item => {
                        state.buyInfo.buyNum += 1
                        state.buyInfo.itemNum += item.num
                        state.buyInfo.money += item.goods.nprice * item.num
                    });
                })


            }
            const submitForm = () => {
                UserSave(state.form).then((e) => {
                    if (e != 1) {
                        ElMessage({
                            message: '成功',
                            type: "success"
                        });
                        UserLists().then((e) => {
                            state.tableData = e.lists;
                            state.role = e.role;
                            state.dep = e.dep;
                            state.dialog = false;
                        })
                    }
                })
            }
            const delData = (row) => {
                ElMessageBox.confirm("删除后不能恢复，确定删除吗？", "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    UserDel({
                        uid: row.uid
                    }).then((e) => {
                        if (e != 1) {
                            ElMessage({
                                message: '成功',
                                type: "success"
                            });
                            UserLists().then((e) => {
                                state.tableData = e
                            })
                        }
                    })
                }).catch(() => {
                    ElMessage({
                        type: "info",
                        message: "取消删除"
                    })
                })
            }
            return {
                ...toRefs(state),
                showDialog,
                submitForm,
                delData
            };
        }
    };
</script>
<style lang="scss" scoped>
    .buy-records {
        width: 100%;
        overflow-y: scroll;

        .buy-info {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 10px;

            span {
                color: red;
            }
        }

        .records-list {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            max-height: 75vh;
            width: 100%;

            .records-item {
                padding: 10px;
                width: 45%;
                margin-right: 10px;
                box-shadow: 0px 0px 6px rgba(0, 0, 0, .12);
                margin-bottom: 10px;
                border-radius: 4px;
                height: 160px;

                .record-num {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    font-size: 12px;
                    margin-bottom: 10px;
                }

                .goods-info {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;

                    .goods-img {
                        width: 100px;
                        height: 120px;
                        margin-right: 20px;
                    }
                }

                .info {
                    div {
                        margin-bottom: 4px;
                    }
                }
            }
        }
    }
</style>