<template>
    <el-button type="primary" size="large" icon="Plus" style="margin-bottom:20px" @click="showDialog(1)"> 添加</el-button>
    <el-table :data="tableData" size="large" border stripe>
        <el-table-column prop="r_id" label="ID" min-width="60" />
        <el-table-column prop="r_name" label="角色名称" min-width="120" />
        <el-table-column prop="status_s" label="状态" min-width="60" />
        <el-table-column prop="time_add" label="创建时间" min-width="100" />
        <el-table-column prop="cz" label="操作" min-width="150">
            <template #default="scope">
                <el-button type="primary" size="large" icon="Edit" @click="showDialog(2, scope.row)">修改</el-button>
                <el-button type="danger" size="large" icon="Delete" @click="delData(scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog" :title="title" destroy-on-close="true">
        <el-form :model="form" label-width="120px" size="large">
            <el-form-item label="角色">
                <el-input v-model="form.r_name" placeholder="请输入角色名称" />
            </el-form-item>
            <el-form-item label="状态">
                <el-select v-model="form.status" placeholder="请选择状态">
                    <el-option label="开启" :value="1" />
                    <el-option label="关闭" :value="0" />
                </el-select>
            </el-form-item>
            <el-form-item label="权限" :label-width="formLabelWidth" prop="menus">
                <el-checkbox-group v-model="form.menus" style="width:100%;">
                    <template v-for="(item, index, key) in menu" :key="key">
                        <!-- <el-checkbox id="parent" :checked="isChecked(rights, item.mid)" :label="item.mid"
                            @change="checkAll(item)">{{ item.label }}</el-checkbox> -->
                        <el-checkbox :checked="isChecked(rights, item.mid)" :label="item.mid">{{
                            item.label
                        }}</el-checkbox>
                        <br />
                        <template v-for="(items, indexs, keys) in item.son" :key="keys">
                            <!-- <el-checkbox :id="'son'+items.parent_id" :checked="isChecked(rights, items.mid)"
                                :label="items.mid">{{ items.label }}</el-checkbox> -->
                            <el-checkbox :checked="isChecked(rights, items.mid)" :label="items.mid">{{
                                items.label
                            }}</el-checkbox>
                        </template>
                        <el-divider />
                    </template>
                </el-checkbox-group>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button size="default" @click="dialog = false">取消</el-button>
                <el-button size="default" type="primary" @click="submitForm()">确认</el-button>
            </span>
        </template>
    </el-dialog>
</template>
<script>
import { toRefs, reactive } from "vue";
import { RoleLists, RoleSave, RoleDel } from "@/network/bewadmin";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
    name: "RoleList",
    setup() {
        const state = reactive({
            tableData: [],
            menu: [],
            dialog: false,
            title: "添加",
            rights: [],
            form: {
                r_name: "",
                status: 1,
                menus: [],
            }
        });
        RoleLists().then((e) => {
            state.tableData = e.lists;
            state.menu = e.menu;
        })
        const showDialog = (e, row) => {
            state.dialog = true;
            if (e == 1) {
                state.form = {
                    r_name: "",
                    status: 1,
                    menus: []
                };
                state.title = "添加";
            } else {
                state.title = "修改";
                state.form = row;
                state.rights = row.rights;
            }
        }
        const submitForm = () => {
            RoleSave(state.form).then((e) => {
                if (e != 1) {
                    ElMessage({ message: '成功', type: "success" });
                    RoleLists().then((e) => {
                        state.tableData = e.lists;
                        state.dialog = false;
                    })
                }
            })
        }
        const delData = (row = {}) => {
            ElMessageBox.confirm("删除后不能恢复，确定删除吗？", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                RoleDel({ r_id: row.r_id }).then((e) => {
                    if (e != 1) {
                        ElMessage({ message: '成功', type: "success" });
                        RoleLists().then((e) => {
                            state.tableData = e.lists;
                        })
                    }
                })
            }).catch(() => {
                ElMessage({
                    type: "info",
                    message: "取消删除"
                })
            })
        }
        // 判断复选框的状态
        const isChecked = (arr, id) => Object.values(arr).indexOf(id) == -1 ? false : true;

        // 全选
        const checkAll = (arr) => {
            // 获取当前多选框的状态
            let status = window.event.target.checked;
            let v = window.event.target.value;
            const items = document.querySelectorAll('#son' + v);
            items.forEach((item) => (item.checked = status));
            // console.log(items);
            // console.log(status);
            // console.log(state.form);
        }
        return {
            ...toRefs(state),
            showDialog,
            submitForm,
            delData,
            isChecked,
            checkAll
        };
    }
};
</script>