import { request } from "./request.js";
export function TestLists(data = {}) {
    return request({
        url: "Bewtest/testlists",
        method: "post",
        data
    });
}
export function TestSave(data = {}) {
    return request({
        url: "Bewtest/testsave",
        method: "post",
        data,
    });
}
export function TestDel(data = {}) {
    return request({
        url: "Bewtest/testdel",
        method: "post",
        data,
    });
}