<template>
    <el-button type="primary" size="large" icon="Plus" style="margin-bottom:20px" @click="showDialog(1)"> 添加</el-button>
    <el-table :data="tableData" size="large" border stripe>
        <el-table-column prop="type_id" label="ID" min-width="60" />
        <el-table-column prop="name" label="角色名称" min-width="120" />
        <el-table-column prop="desc" label="备注" min-width="120" />
        <el-table-column prop="status_s" label="状态" min-width="60" />
        <el-table-column prop="time_add" label="创建时间" min-width="100" />
        <el-table-column prop="cz" label="操作" min-width="150">
            <template #default="scope">
                <el-button type="primary" size="large" icon="Edit" @click="showDialog(2, scope.row)">修改</el-button>
                <el-button type="danger" size="large" icon="Delete" @click="delData(scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog" :title="title" destroy-on-close="true">
        <el-form :model="form" label-width="120px" size="large">
            <el-form-item label="分类名称">
                <el-input v-model="form.name" placeholder="请输入分类名称" />
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="form.desc" placeholder="请输入备注" />
            </el-form-item>
            <el-form-item label="状态">
                <el-select v-model="form.status" placeholder="请选择状态">
                    <el-option label="开启" :value="1" />
                    <el-option label="关闭" :value="0" />
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button size="default" @click="dialog = false">取消</el-button>
                <el-button size="default" type="primary" @click="submitForm()">确认</el-button>
            </span>
        </template>
    </el-dialog>
</template>
<script>
import { toRefs, reactive } from "vue";
import { TypeLists, TypeSave, TypeDel } from "@/network/shop";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
    name: "GoodsType",
    setup() {
        const state = reactive({
            tableData: [],
            menu: [],
            dialog: false,
            title: "添加",
            form: {
                name: "",
                desc: "",
                status: 1,
            }
        });
        TypeLists().then((e) => {
            state.tableData = e;
        })
        const showDialog = (e, row) => {
            state.dialog = true;
            if (e == 1) {
                state.form = {
                    name: "",
                    desc: "",
                    status: 1,
                };
                state.title = "添加";
            } else {
                state.title = "修改";
                state.form = row;
            }
        }
        const submitForm = () => {
            TypeSave(state.form).then((e) => {
                if (e != 1) {
                    ElMessage({ message: '成功', type: "success" });
                    TypeLists().then((e) => {
                        state.tableData = e;
                        state.dialog = false;
                    })
                }
            })
        }
        const delData = (row = {}) => {
            ElMessageBox.confirm("删除后不能恢复，确定删除吗？", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                TypeDel({ type_id: row.type_id }).then((e) => {
                    if (e != 1) {
                        ElMessage({ message: '成功', type: "success" });
                        TypeLists().then((e) => {
                            state.tableData = e;
                        })
                    }
                })
            }).catch(() => {
                ElMessage({
                    type: "info",
                    message: "取消删除"
                })
            })
        }

        return {
            ...toRefs(state),
            showDialog,
            submitForm,
            delData,
        };
    }
};
</script>