<template>
    <el-button type="primary" icon="Plus" style="margin-bottom:20px" @click="showDialog(1)"> 添加</el-button>
    <el-table :data="tableData" size="small" border stripe>
        <el-table-column prop="gid" label="ID" min-width="25" align="center" />
        <el-table-column prop="title" label="商品标题" min-width="80" align="center" />
        <el-table-column prop="type_id" label="商品分类" min-width="80" align="center">
            <template #default="scope">
                <div v-for="item in types">
                    <span v-if="item.type_id == scope.row.type_id">{{ item.name }}</span>
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="desc" label="商品描述" min-width="150" />
        <!-- <el-table-column prop="content" label="商品介绍" min-width="120" /> -->
        <el-table-column prop="img" label="商品图片" min-width="80" align="center">
            <template #default="scope">
                <template v-for="(item, i) in scope.row.img_s" :key="i">
                    <el-image style="width: 80%;" :preview-src-list="['https://test-server.yinheyibei.cn'+item.data]"
                        :src="'https://test-server.yinheyibei.cn'+item.data" fit="fill" preview-teleported />
                </template>
            </template>
        </el-table-column>
        <el-table-column prop="oprice" label="原价" min-width="80" align="center" />
        <el-table-column prop="nprice" label="现价" min-width="80" align="center" />
        <el-table-column prop="num" label="库存" min-width="60" align="center" />
        <el-table-column prop="sales" label="销量" min-width="60" align="center" />
        <el-table-column prop="sort" label="排序" min-width="60" align="center" />
        <el-table-column prop="is_top_s" label="置顶" min-width="60" align="center">
            <template #default="scope">
                <el-tag size="large" effect="plain" :type="scope.row.is_top == 1 ? 'info' : 'danger'">{{ scope.row.is_top_s
                }}</el-tag>
            </template>
        </el-table-column>
        <el-table-column prop="status_s" label="状态" min-width="60" align="center">
            <template #default="scope">
                <el-tag size="large" effect="dark" :type="scope.row.status == 1 ? 'primary' : 'info'">{{ scope.row.status_s
                }}</el-tag>
            </template>
        </el-table-column>
        <el-table-column prop="time_add" label="创建时间" min-width="80" align="center">
            <template #default="scope">
                <div v-html='(scope.row.time_add).replace(" ", "<br />")'></div>
            </template>
        </el-table-column>
        <el-table-column prop="time_update" label="更新时间" min-width="80" align="center">
            <template #default="scope">
                <div v-html='(scope.row.time_update).replace(" ", "<br />")'></div>
            </template>
        </el-table-column>
        <el-table-column prop="cz" label="操作" min-width="150">
            <template #default="scope">
                <el-button type="primary" icon="Edit" @click="showDialog(2, scope.row)">修改</el-button>
                <el-button type="danger" icon="Delete" @click="delData(scope.row)">删除</el-button>
            </template>
        </el-table-column>

    </el-table>
    <el-pagination layout="prev, pager, next, jumper, total, size" background :total="total" hide-on-single-page="true"
        @current-change="CurrentChange" />
    <!-- @close="colseDialog()" -->
    <el-dialog v-model="dialog" :title="title" destroy-on-close="false" fullscreen>
        <!-- :inline="true" -->
        <el-form :model="form" label-width="120px" size="large">
            <el-form-item label="商品标题">
                <el-input v-model="form.title" placeholder="请输入商品标题" />
            </el-form-item>
            <el-form-item label="商品描述">
                <el-input v-model="form.desc" placeholder="请输入商品描述" />
            </el-form-item>
            <el-form-item label="商品分类">
                <el-select v-model="form.type_id" placeholder="请选择商品分类">
                    <el-option v-for="item in types" :key="item.type_id" :label="item.name" :value="item.type_id" />
                </el-select>
            </el-form-item>
            <el-form-item label="原价">
                <el-input-number v-model="form.oprice" :min="1" :precision="2" />
            </el-form-item>
            <el-form-item label="现价">
                <el-input-number v-model="form.nprice" :min="1" :precision="2" />
            </el-form-item>
            <el-form-item label="是否上架">
                <el-radio-group v-model="form.status" name="status">
                    <el-radio :label="1">上架</el-radio>
                    <el-radio :label="0">下架</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="是否置顶">
                <el-radio-group v-model="form.is_top" name="is_top">
                    <el-radio :label="1">默认</el-radio>
                    <el-radio :label="0">置顶</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="排序">
                <el-input-number v-model="form.sort" :min="1" :precision="0" />
            </el-form-item>
            <el-form-item label="库存">
                <el-input-number v-model="form.num" :min="1" :precision="0" />
            </el-form-item>
            <el-form-item label="商品图片" size="large">
                <el-upload :action="upload_img" list-type="picture-card" :data="{ Authorization: token }"
                    :on-success="UploadImages" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
                    v-model:file-list="imgList" multiple="true">
                    <el-icon>
                        <Plus />
                    </el-icon>
                </el-upload>
                <el-dialog v-model="dialogVisible">
                    <el-image w-full :src="dialogImageUrl" alt="Preview Image" preview-teleported />
                </el-dialog>
            </el-form-item>
            <el-fotm-itme lable="商品介绍">
                <!-- wangeditor富文本 -->
                <div style="border: 1px solid #ccc">
                    <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig"
                        :mode="mode" />
                    <Editor style="height: 500px; overflow-y: hidden;" v-model="form.content"
                        :defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" />
                </div>
            </el-fotm-itme>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button size="default" @click="dialog = false">取消</el-button>
                <el-button size="default" type="primary" @click="submitForm()">确认</el-button>
            </span>
        </template>
    </el-dialog>
</template>
<script>
    // VUE3 相关组件
    import {
        toRefs,
        ref,
        reactive,
        shallowRef
    } from "vue";
    import {
        GoodsLists,
        GoodsSave,
        GoodsDel,
        DelUpload
    } from "@/network/shop";
    // element-puls是element3.0 UI框架
    // npm安装：npm install element-plus --save
    // yarn安装：yarn add element-plus
    import {
        ElMessage,
        ElMessageBox
    } from "element-plus";
    // 引入富文本样式及组件
    // yarn add @wangeditor/editor
    // # 或者 npm install @wangeditor/editor --save
    // 官网：https://www.wangeditor.com/
    import '@wangeditor/editor/dist/css/style.css' // 引入 css
    import {
        Editor,
        Toolbar
    } from '@wangeditor/editor-for-vue'

    export default {
        name: "GoodsList",
        components: {
            Editor,
            Toolbar
        },
        setup() {
            const state = reactive({
                // 表格数据
                tableData: [],
                total: 1,
                // 表单状态
                dialog: false,
                // 表单标题
                title: "添加",
                // 商品分类
                types: [],
                // from表单需提交的数据
                form: {
                    title: "",
                    type_id: 0,
                    desc: "",
                    content: "",
                    img: "",
                    sort: 1,
                    is_top: 1,
                    status: 1,
                    num: 1,
                    oprice: 0,
                    nprice: 0,
                },
                //图片上传地址
                upload_img: 'https://test-server.yinheyibei.cn/admin/Index/upload_img',
                //图片上传时需要带token去验证 
                token: window.localStorage.getItem('token'),
                //多图上传  图片弹框放大
                dialogImageUrl: "",
                //是否大图显示
                dialogVisible: false,
                //用于存放图片上传时的临时路径
                imgList: [],
                mode: "default"
            });
            // 获取商品信息列表
            GoodsLists().then((e) => {
                state.tableData = e == 1 ? [] : e.lists;
                console.log(state.tableData)
                state.types = e == 1 ? [] : e.types;
                state.total = e.total;
            })
            // 显示弹框（表单） 根据参数判断表单是新增数据还是修改数据
            const showDialog = (e, row) => {
                state.dialog = true;
                if (e == 1) {
                    state.form = {
                        title: "",
                        type_id: '',
                        desc: "",
                        content: "",
                        img: "",
                        sort: 1,
                        is_top: 1,
                        status: 1,
                        num: 1,
                        oprice: 0,
                        nprice: 0,
                    };
                    state.imgList = [];
                    state.title = "添加";
                } else {
                    state.title = "修改";
                    state.form = row;
                    if (state.form.img) {
                        state.imgList = row.img_s.map((item) => {
                            return {
                                name: item.uid,
                                url: 'https://test-server.yinheyibei.cn' + item.data,
                                data: item.data
                            }
                        })
                        console.log(state.imgList)
                        state.form.img = row.img_s.map((item) => {
                            return item.data;
                        })
                    } else {
                        state.imgList = [];
                    }
                }
            }
            // 提交form表单
            const submitForm = () => {
                console.log(state.form)

                // state.form.img = state.form.img.join(";");
                GoodsSave(state.form).then((e) => {
                    if (e != 1) {
                        ElMessage({
                            message: '成功',
                            type: "success"
                        });
                        GoodsLists().then((e) => {
                            state.tableData = e.lists;
                            state.dialog = false;
                        })
                    }
                })
            }
            // 当from表单未提交时 删除已上传的临时图片
            // const colseDialog = () => {
            //     console.log(state.imgList);
            //     if ((state.imgList).length > 0) {
            //         DelUpload({ Authorization: state.token, imgs: state.imgList.map((item) => item.response.data) }).then((e) => {
            //             state.imgList = [];
            //         })
            //     }
            // }
            // 删除数据
            const delData = (row = {}) => {
                ElMessageBox.confirm("删除后不能恢复，确定删除吗？", "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    GoodsDel({
                        gid: row.gid
                    }).then((e) => {
                        if (e != 1) {
                            ElMessage({
                                message: '成功',
                                type: "success"
                            });
                            GoodsLists().then((e) => {
                                state.tableData = e.lists;
                            })
                        }
                    })
                }).catch(() => {
                    ElMessage({
                        type: "info",
                        message: "取消删除"
                    })
                })
            }

            // 图片上传成功的回调函数 将图片上传成功时的图片路径保存到form表单中 方便后台处理
            const UploadImages = (response, uploadfile) => {
                state.form.img = uploadfile
                state.form.img = state.imgList.map((item) => {
                    console.log(item.response)
                    return item.data != null ? item.data : item.response.data
                });
            }
            // 图片上传时 移除图片的回调函数 将移除的图片路径从form表单中移除
            const handleRemove = (uploadFile, uploadFiles) => {
                console.log(uploadFiles);
                state.form.img = uploadFiles.map((item) => {
                    return item.raw != null ? item.response.data : item.data
                });
                // //释放文件资源
                // DelUpload({ Authorization: state.token, imgs: uploadFile.response.data }).then((e) => { })
            };
            // 点击已上传图片的回调函数 查看大图
            const handlePictureCardPreview = (uploadFile, uploadFiles) => {
                state.dialogImageUrl = uploadFile.url;
                state.dialogVisible = true;
                console.log('state.dialogImageUrl', state.dialogImageUrl)
            };


            // 富文本相关
            // 编辑器实例，必须用 shallowRef
            const editorRef = shallowRef()
            // 菜单配置
            const toolbarConfig = {}
            // 编辑器配置
            const editorConfig = {
                placeholder: '请输入内容...',
                // 所有的菜单配置，都要在 MENU_CONF 属性下
                MENU_CONF: {
                    // 配置上传图片
                    uploadImage: {
                        // 上传名称，默认值 'wangeditor-uploaded-image'
                        fieldName: "file",
                        // 单个文件的最大体积限制，默认为 2M
                        maxFileSize: 4 * 1024 * 1024, // 4M
                        // 服务端地址
                        server: state.upload_img + "_s",
                        // 自定义上传参数，例如传递验证的 token 等。
                        meta: {
                            Authorization: state.token,
                        }
                    }
                }
            }
            // 禁用Video 视频上传
            toolbarConfig.excludeKeys = [
                "insertVideo",
                "uploadVideo",
                "editVideoSize"
            ]
            //组件销毁时，也及时销毁编辑器
            editorConfig.onDestroyed = (editor) => {
                editor.destroy()
            }
            // 记录 editor 实例，重要！
            const handleCreated = (editor) => {
                editorRef.value = editor
            }
            const CurrentChange = (e) => {
                GoodsLists({
                    p: e
                }).then((res) => {
                    console.log(e);
                    state.tableData = res.lists;
                    // state.total = res.total;
                    // state.types = res.types;
                });
            }

            return {
                ...toRefs(state),
                //弹窗、提交form表单、删除数据、取消按钮
                showDialog,
                submitForm,
                delData, //cancelForm,
                //文件相关函数 上传成功勾子、删除上传的图片的钩子、点击放大的钩子、表单取消提交时删除已上传图片的函数 colseDialog,
                UploadImages,
                handleRemove,
                handlePictureCardPreview,
                //富文本相关
                editorRef,
                toolbarConfig,
                editorConfig,
                handleCreated,
                // 分页
                CurrentChange,
            };
        }
    };
</script>