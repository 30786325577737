<template>
    <div>
    <div style="margin-bottom: 15px">
        <el-button type="primary" size="large" icon="Plus" @click="ShowDialog(1)"> 添加</el-button>
        <el-button type="primary" size="large" icon="Edit" @click="ShowDialog(2)">修改</el-button>
        <el-button type="danger" size="large" icon="Delete" @click="DelButtn">删除</el-button>
    </div>
    <el-space fill wrap :fill-ratio="fillRatio" :direction="direction" style="width: 100%">
        <el-form size="large" :model="search" class="demo-form-inline">
            <el-form-item label="内容搜索">
                <el-col :span="4">
                    <el-select v-model="search.type" placeholder="请选择要搜索的字段" style="width: 100%">
                        <el-option label="文本" value="1" />
                        <el-option label="富文本" value="2" />
                    </el-select>
                </el-col>
                <el-col :span="11">
                    <el-input v-model="search.value" placeholder="请输入要搜索的内容" />
                </el-col>
            </el-form-item>
            <el-form-item label="参照搜索">
                <el-col :span="4">
                    <el-select v-model="search.test_reference" placeholder="请选择要搜索的参照" style="width: 100%">
                        <el-option label="全部" value="-1" />
                        <el-option label="开启" value="1" />
                        <el-option label="关闭" value="0" />
                    </el-select>
                </el-col>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSearch()">搜索</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="tableData" border highlight-current-row ref="multipleTable" @selection-change="handleSelectionChange" size="large">
        <el-table-column type="selection" width="55" label="选择" />
        <template v-for="(item, index) in columns" :key="index">
          <template v-if="item.dataIndex == 'test_img_s'">
            <el-table-column :label="item.title" width="100" :prop="item.dataIndex">
              <template #default="scope">
                <div style="display: flex; align-items: center" v-if="scope.row.test_img">
                  <el-image :preview-src-list="[scope.row.test_img_s]" :src="scope.row.test_img_s" style="width: 70%" preview-teleported />
                </div>
              </template>
            </el-table-column>
          </template>
          <template v-else-if="item.dataIndex == 'test_img_many_s'">
            <el-table-column :label="item.title" width="100" :prop="item.dataIndex">
              <template #default="scope">
                <template v-for="(it, i) in scope.row.test_img_many_s" :key="i">
                  <el-image :preview-src-list="[it.url]" :src="it.url" style="width: 60%" preview-teleported />
                </template>
              </template>
            </el-table-column>
          </template>
          <template v-else-if="item.dataIndex == 'test_url'">
            <el-table-column :prop="item.dataIndex" :label="item.title" width="180">
              <template #default="scope">
                <a target="_bank" :href="scope.row.test_url">{{ scope.row.test_url }}</a>
              </template>
            </el-table-column>
          </template>
          <template v-else>
            <el-table-column :prop="item.dataIndex" :label="item.title" />
          </template>
        </template>
      </el-table>
    </el-space>
    <el-pagination layout="prev, pager, next, jumper, total, size" background :total="total" @current-change="CurrentChange" />
  </div>

  <!-- 弹出表单 -->
  <el-dialog v-model="dialogFormVisible" :title="CurdTitle" fullscreen>
    <el-form :model="form" :rules="GroupRules" ref="ruleFormsss" size="large">
      <el-form-item label="输入框:" :label-width="formLabelWidth" size="large" prop="test_input">
        <el-input v-model="form.test_input" autocomplete="off" name="account" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="富文本:" :label-width="formLabelWidth" size="large" prop="test_rich">
        <el-input v-model="form.test_rich" :autosize="{ minRows: 5, maxRows: 8 }" type="textarea" placeholder="请输入富文本" />
      </el-form-item>
      <el-form-item label="图片:" :label-width="formLabelWidth" size="large" prop="test_img_s">
        <el-upload class="avatar-uploader" :action="upload_img" :show-file-list="false" :data="{ Authorization: token }" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
          <img v-if="form.test_img_s" :src="form.test_img_s" class="avatar" />
          <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
        </el-upload>
      </el-form-item>
      <el-form-item label="多图片:" :label-width="formLabelWidth" size="large" prop="test_img_many">
        <el-upload
          :action="upload_img"
          list-type="picture-card"
          :data="{ Authorization: token }"
          :on-success="UploadImages"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :file-list="imgList"
        >
          <el-icon><Plus /></el-icon>
        </el-upload>
        <el-dialog v-model="dialogVisible">
          <img w-full :src="dialogImageUrl" alt="Preview Image" preview-teleported />
        </el-dialog>
      </el-form-item>
      <el-form-item label="参照:" :label-width="formLabelWidth" size="large" prop="test_reference">
        <el-select v-model="form.test_reference" placeholder="请选择年月日时分秒时间戳">
          <el-option label="开启" :value="1">开启</el-option>
          <el-option label="关闭" :value="0">关闭</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="年月日时分秒时间戳:" :label-width="formLabelWidth" size="large" prop="test_time">
        <el-date-picker v-model="form.test_time" type="datetime" placeholder="请选择年月日时分秒时间戳" />
      </el-form-item>
      <el-form-item label="年月日:" :label-width="formLabelWidth" size="large" prop="test_data">
        <el-date-picker v-model="form.test_data" type="date" placeholder="请选择年月日" />
      </el-form-item>
      <el-form-item label="年月日时分秒:" :label-width="formLabelWidth" size="large" prop="test_datatime">
        <el-date-picker v-model="form.test_datatime" type="datetime" placeholder="请选择年月日时分秒" />
      </el-form-item>
      <el-form-item label="url:" :label-width="formLabelWidth" size="large" prop="test_url">
        <el-input v-model="form.test_url" autocomplete="off" name="test_url" placeholder="请输入url" />
      </el-form-item>
      <el-form-item label="单选框:" :label-width="formLabelWidth" size="large" prop="test_radio">
        <el-radio-group v-model="form.test_radio" name="status">
          <el-radio :label="1">启用</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="富文本:" :label-width="formLabelWidth" prop="test_rich_editor">
        <div>
          <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode" />
          <Editor style="height: 500px; overflow-y: hidden" v-model="form.test_rich_editor" :defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" />
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="default" @click="cancelForm(ruleFormsss)">取消</el-button>
        <el-button size="default" type="primary" @click="submitForm(ruleFormsss)">提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
    import { ElMessage, ElMessageBox } from "element-plus";
    import { TestLists, TestSave, TestDel } from "@/network/bewtest";
    import { toRefs, reactive, ref, shallowRef } from "vue";
    import { Edit, Delete, Plus, Search } from "@element-plus/icons-vue";
    import "@wangeditor/editor/dist/css/style.css";
    import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
    export default {
        components: {
            ElMessage,
            Edit,
            Delete,
            Plus,
            Search,
            Editor,
            Toolbar,
        },
        setup() {
            const Data = reactive({
                columns: [
                    {
                        title: "ID",
                        dataIndex: "test_id",
                    },
                    {
                        title: "文本",
                        dataIndex: "test_input",
                    },
                    {
                        title: "富文本",
                        dataIndex: "test_rich",
                    },
                    {
                        title: "图片(点击放大)",
                        dataIndex: "test_img_s",
                    },
                    {
                        title: "多图(点击放大)",
                        dataIndex: "test_img_many_s",
                    },
                    {
                        title: "参照",
                        dataIndex: "test_reference_s",
                    },
                    {
                        title: "年月日时分秒 时间戳",
                        dataIndex: "test_time_s",
                    },
                    {
                        title: "年月日",
                        dataIndex: "test_data",
                    },
                    {
                        title: "年月日时分秒",
                        dataIndex: "test_datatime",
                    },
                    {
                        title: "网址",
                        dataIndex: "test_url",
                    },
                    {
                        title: "单选框",
                        dataIndex: "test_radio_s",
                    }
                ],
                tableData: [],
                total: 1,
                search : {
                    type : '1',
                    value : '',
                    test_reference : '-1'
                },
                group: [],
                plainOptions: [],
                Selection: 0,
                templateSelection: 0,
                direction: "horizontal",
                fillRatio: 30,
                dialogFormVisible: false,
                formLabelWidth: "140px",
                form: {
                    test_id: 0,
                    test_input: "",
                    test_rich: "",
                    test_img: "",
                    test_img_s: "",
                    test_img_many: "",
                    test_reference: "",
                    test_time: "",
                    test_data: "",
                    test_datatime: "",
                    test_url: "",
                    test_radio: 1,
                    test_rich_editor: "",
                },
                Rest_test_img_many: "",
                CurdTitle: "添加",
                mode: "default",
                submitMessage: "",
                imgList: [],
                // 图片上传地址
                upload_img: "http://admin-thinkphp-element-vue-learning-api.ouyangke.com/index.php/admin/Index/upload_img",
                // 富文本
                mode: "default",
                token: window.localStorage.getItem("token"),
                //多图上传  图片弹框放大
                dialogImageUrl: "",
                dialogVisible: false,
            });
            //获取数据列表
            TestLists().then((res) => {
                Data.tableData = res.lists;
                Data.total = res.total;
            });
            // 翻页方法
            const CurrentChange = (e)=>{
                TestLists({p:e}).then((res) => {
                    Data.tableData = res.lists;
                });
            }
            // 搜索
            const onSearch = () => {
                TestLists({type:Data.search.type,value:Data.search.value,test_reference:Data.search.test_reference}).then((res) => {
                    Data.tableData = res.lists;
                });
            }
            const ShowDialog = (e) => {
                if (e == 1) {
                    Data.form = {
                        test_id: 0,
                        test_input: "",
                        test_rich: "",
                        test_img: "",
                        test_img_s: "",
                        test_img_many: "",
                        test_reference: "",
                        test_time: "",
                        test_data: "",
                        test_datatime: "",
                        test_url: "",
                        test_radio: 1,
                        test_rich_editor: "",
                    };
                    Data.imgList = [];
                    Data.CurdTitle = "添加";
                    Data.submitMessage = "添加成功";
                    Data.dialogFormVisible = true;
                } else if (e == 2) {
                    if (Data.templateSelection == 0) {
                        ElMessage({ message: "请务必选择一条数据", type: "error" });
                    } else {
                        Data.submitMessage = "修改成功";
                        Data.form = Data.templateSelection;
                        if (Data.form.test_img_many) {
                            Data.imgList = Data.form.test_img_many_s.map((item) => {
                                return { name: item.uid, url: item.url, data: item.data };
                            });
                            Data.form.test_img_many = Data.form.test_img_many_s.map((item) => {
                                return item.data;
                            });
                        } else {
                            Data.imgList = [];
                        }
                        Data.CurdTitle = "编辑";
                        Data.dialogFormVisible = true;
                    }
                }
            };
            //表格数据验证
            const GroupRules = {
                test_input: [{ required: true, message: "输入框不能为空", trigger: "blur" }],
                test_rich: [{ required: true, message: "富文本不能为空", trigger: "blur" }],
                test_reference: [{ required: true, message: "参照不能为空", trigger: "blur" }],
            };
            const ruleFormsss = ref(null);
            //提交按钮
            const submitForm = () => {
                ruleFormsss.value.validate((valid) => {
                    if (valid) {
                        Data.form.test_img_many = Data.form.test_img_many.join(";");
                        TestSave(Data.form).then((res) => {
                            if (res != 1) {
                                ElMessage({ message: Data.submitMessage, type: "success" });
                            }
                            setTimeout(() => {
                                // 重新请求列表
                                TestLists().then((res) => {
                                    if (res != 1) {
                                        Data.tableData = res.lists;
                                        Data.templateSelection = 0;
                                        // Data.tableData = res.lists;
                                        // Data.group = res.group;
                                        Data.dialogFormVisible = false;
                                        ruleFormsss.value.resetFields();
                                        // 此时再调用resetFields()方法是为了清除表单验证而出现的错误
                                        setTimeout(() => {
                                            ruleFormsss.value.clearValidate();
                                        }, 1000);
                                        location.reload();
                                    }
                                });
                            }, 1000);
                        });
                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            };
            //取消按钮
            const cancelForm = () => {
                if (!ruleFormsss) return;
                ruleFormsss.value.clearValidate();
                //清空临时存储
                Data.templateSelection = 0;
                //关闭弹窗
                Data.dialogFormVisible = false;
                // 清空用户选择按钮
                multipleTable.value.clearSelection();
            };
            const multipleTable = ref(null);
            //单选按钮
            const handleSelectionChange = function (val) {
                // val为返回一个selection包括所有的选中
                let b = multipleTable.value.getSelectionRows().pop();
                if (val.length > 1) {
                    multipleTable.value.clearSelection();
                    multipleTable.value.toggleRowSelection(b, true);
                }
                multipleTable.value.setCurrentRow(b);
                if (b) {
                    Data.templateSelection = b;
                } else {
                    Data.templateSelection = 0;
                }
            };
            //删除按钮
            const DelButtn = () => {
            if (Data.templateSelection == 0) {
                ElMessage({ message: "请务必选择一条数据", type: "error" });
                return false;
            } else {
                ElMessageBox.confirm("删除后不能恢复，确定删除吗？", "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                .then(() => {
                    TestDel({ test_id: Data.templateSelection.test_id }).then((res) => {
                        if (res) {
                            if (res != 1) {
                                ElMessage({
                                    type: "success",
                                    message: "删除成功",
                                });
                            }
                            TestLists().then((res) => {
                                if (res) {
                                    Data.tableData = res.lists;
                                }
                            });
                            Data.templateSelection = [];
                        }
                    });
                })
                .catch(() => {
                    ElMessage({
                        type: "info",
                        message: "取消删除",
                    });
                });
            }
            };
            // 管理员token，上传图片接口需要
            const token = window.localStorage.getItem("token");
            // 编辑器实例，必须用 shallowRef
            const editorRef = shallowRef();
            const toolbarConfig = {};
            // 编辑器配置
            const editorConfig = {
                placeholder: "请填写内容",
                // 所有的菜单配置，都要在 MENU_CONF 属性下
                MENU_CONF: {
                    // 配置上传图片
                    uploadImage: {
                        // 上传名称，默认值 'wangeditor-uploaded-image'
                        fieldName: "file",
                        // 单个文件的最大体积限制，默认为 2M
                        maxFileSize: 1 * 1024 * 1024, // 1M
                        // 服务端地址
                        server: Data.upload_img + "_s",
                        // 自定义上传参数，例如传递验证的 token 等。
                        meta: {
                            Authorization: token,
                        }
                    }
                }
            };
            const handleCreated = (editor) => {
                editorRef.value = editor; // 记录 editor 实例，重要！
            };

            //单图片上传
            const beforeAvatarUpload = (file) => {
                const isJPG = file.type === "image/png" || "image/jpg" || "image/jpeg";
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    ElMessage({ message: "上传头像图片只能是 JPG/PNG/JPEG 格式!", type: "error" });
                }
                if (!isLt2M) {
                    ElMessage({ message: "上传头像图片大小不能超过 2MB!", type: "error" });
                }
                return isJPG && isLt2M;
            };
            const handleAvatarSuccess = (response, uploadfile) => {
                Data.form.test_img = response.data;
                Data.form.test_img_s = URL.createObjectURL(uploadfile.raw);
            };
            //多图文件上传
            const UploadImages = (response, uploadfile) => {
            Data.form.test_img_many = Data.imgList.map((item) => {
                if (item.data) {
                return item.data;
                } else {
                return item.response.data;
                }
            });
            console.log(Data.form.test_img_many);
            };
            const handlePictureCardPreview = (uploadFile, uploadFiles) => {
                Data.dialogImageUrl = uploadFile.url;
                Data.dialogVisible = true;
            };
            const handleRemove = (uploadFile, uploadFiles) => {
                Data.form.test_img_many = uploadFiles.map((item) => {
                    if (item.raw != null) return item.response.data;
                    else return item.data;
                });
                console.log(Data.form.test_img_many);
            };

            return {
                ...toRefs(Data),
                CurrentChange,
                onSearch,
                submitForm,
                cancelForm,
                GroupRules,
                ruleFormsss,
                ShowDialog,
                multipleTable,
                handleSelectionChange,
                DelButtn,
                beforeAvatarUpload,
                handleAvatarSuccess,

                toolbarConfig,
                handleCreated,
                editorConfig,
                editorRef,
                //多图片上传
                UploadImages,
                handlePictureCardPreview,
                handleRemove
            };
        }
    };
</script>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 104px;
    height: 104px;
    text-align: center;
}
.avatar-uploader .avatar {
    width: 104px;
    height: 104px;
    display: block;
}
</style>